<template>
  <div class="home">
    <button class="button field is-info is-light" @click="isCardModalActive = true">
      <b-icon icon="plus"></b-icon>
      <span>Novo solicitante</span>
    </button>
    <b-table :data="allRequesters" ref="table" paginated per-page="10" detailed detail-key="id" :striped="true"
      :debounce-search="1000" @details-open="row => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
      " :show-detail-icon="true" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
      aria-current-label="Current page">
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
        }}</b-table-column>

      <b-table-column field="name" label="Nome" :searchable="true" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="email" label="E-mail" sortable :searchable="true" v-slot="props">{{ props.row.email
        }}</b-table-column>

      <b-table-column field="profile" label="Cargo" sortable :searchable="true" v-slot="props">{{
        getProfileByID(props.row.profile) }}</b-table-column>

      <b-table-column label="Opções" v-slot="props">
        <button class="button is-small is-light" @click.prevent="
          onEdit(props.row)
        isCardModalActive = true
          ">
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button class="button is-small is-danger" @click.prevent="onDelete(props.row.id)">
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Empresas: </strong>
                <small>{{ props.row.identification }}</small>
                <br />
                <span v-for="(item, index) in props.row.customers" :key="index">
                  <small>- {{ item.name.toUpperCase() }}</small>
                  <br />
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="E-mail">
              <b-input type="email" v-model="model.email"></b-input>
            </b-field>
            <b-field label="Cargo">
              <b-select placeholder="Selecione um cargo" required expanded v-model="model.profile">
                <option v-for="(item, index) in profiles" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Empresas">
              <b-select expanded multiple native-size="8" v-model="model.customers">
                <option v-for="(item, index) in allCustomers" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Centro de custo"></b-field>
            <b-field v-for="(item, i) in model.cost_centers" :key="i">
              <div class="columns">
                <div class="column">
                  <b-select placeholder="Selecione um centro de custo" required v-model="item.cost_center_id">
                    <option v-for="(item, index) in getCostCenters()" :key="index" :value="item.id">
                      {{ item.code }} - {{ item.name }} ({{ item.customer_name }})
                    </option>
                  </b-select>
                </div>
                <div class="column">
                  <button class="button is-small is-danger" @click.prevent="removeCostCenter(i)">
                    <b-icon icon="delete" size="is-small"></b-icon>
                  </button>
                </div>
              </div>
            </b-field>
            <button class="button field is-info is-light" @click="addCostCenter">
              <b-icon icon="plus"></b-icon>
              <span>Adicionar centro de custo</span>
            </button>
            <!-- <b-field label="Permissões">
              <b-select
                expanded
                multiple
                native-size="8"
                v-model="model.permissions"
              >
                <option
                  v-for="(item, index) in allPermissions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </b-select>
            </b-field> -->
            <b-button @click="save" type="button field is-info" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '../event-bus.js'
import service from '../features/requesters/store/service'
import Requester from '../models/requester'
export default {
  name: 'Requesters',
  data() {
    return {
      model: Requester.model,
      showDetailIcon: false,
      isCardModalActive: false,
      allPermissions: [
        { text: 'Visualizar relatórios', value: 'requester.reports.view' },
        { text: 'Lançar serviços', value: 'requester.services.create' },
        { text: 'Visualizar passageiros', value: 'requester.passengers.view' },
      ],
      allCostCenters: [],
      profiles: [
        {
          id: 1,
          name: 'Master',
        },
        {
          id: 2,
          name: 'Solicitante',
        },
        {
          id: 3,
          name: 'Aprovador',
        },
        {
          id: 4,
          name: 'Suporte',
        },
      ],
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('requesters', ['allRequesters']),
    ...mapState('customers', ['allCustomers']),
  },
  methods: {
    ...mapActions('requesters', ['getAllRequesters']),
    ...mapActions('customers', ['getAllCustomers']),
    async init() {
      await this.getAllRequesters()
      await this.getAllCustomers()

      console.log(this.allRequesters)
    },
    async save() {
      const requester = { ...this.model }

      console.log('save requester ', requester.permissions)

      try {
        const save = requester.id
          ? await service.updateRequester(requester)
          : await service.saveRequester(requester)

        await this.$success('Solicitante')
        await location.reload(true)
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },

    getCostCenters() {
      let result = []
      for (let item of this.model.customers) {
        let customer = this.allCustomers.find((x) => x.id == item);

        console.log('customer ', customer)

        if (!customer || !customer.cost_center) continue;

        result = result.concat(customer.cost_center.map((x) => { return { customer_name: customer.name, ...x } }))
      }

      console.log('result ', result)

      return result;
    },

    addCostCenter() {
      this.model.cost_centers.push({ cost_center_id: null })
    },

    removeCostCenter(index) {
      this.model.cost_centers.splice(index, 1)
    },

    getProfileByID(profile) {
      let result = this.profiles.find((x) => x.id == profile);
      return result ? result.name : "N/A"
    },
    onEdit(item) {
      this.model = { ...item }

      this.model.permissions = item.permissions.map(x => x.value)
      this.model.customers = item.customers.map(x => x.id)
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o solicitante?')

        if (result.isConfirmed) {
          await service.deleteRequester(id)

          await this.$delete('Cliente')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
